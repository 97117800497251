@import 'styles/new-design/main.scss';

:root .mat-tooltip {
  &.measuring-tooltip {
    white-space: pre-line;

    .mdc-tooltip__surface {
      text-align: left;
      min-width: 220px;
    }
  }

  &.lifting-power-tooltip {
    white-space: pre-line;

    .mdc-tooltip__surface {
      text-align: left;
      min-width: 235px;
    }
  }
}
